import {
  Box, Flex, Heading, Wrap, WrapItem,
} from '@chakra-ui/layout';
import {
  Modal, ModalBody, ModalContent, ModalOverlay,
} from '@chakra-ui/modal';
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';

import * as styles from 'styles/shared.module.scss';
import {
  Navigation, Pagination, Scrollbar, A11y, EffectFade,
} from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';
import 'swiper/scss/effect-fade';
import { graphql, useStaticQuery } from 'gatsby';

export const query = graphql`
  {
    allStrapiGalleries {
      nodes {
        title
        id
        images {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;

function GalleryPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const allData = useStaticQuery(query);

  const data = allData.allStrapiGalleries.nodes;

  return (
    <Flex justify="center" align="center" direction="column">

      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        isCentered
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent style={{ borderRadius: 25 }}>
          <ModalBody style={{ padding: 0, margin: 0, borderRadius: 25 }}>
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, A11y, EffectFade]}
              effect="fade"
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              initialSlide={currentIndex}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              {images.map((image) => {
                const gatsbyImg = getImage(image.localFile);

                return (
                  <SwiperSlide>
                    <GatsbyImage image={gatsbyImg} />
                  </SwiperSlide>
                );
              })}
            </Swiper>

          </ModalBody>
        </ModalContent>
      </Modal>

      {
        data.map((gallery, index) => (
          <Box
            key={gallery.id}
            className={
            index % 2 === 0
              ? styles.grayPresentationContainer
              : styles.whitePresentationContainer
            }

          >
            <Flex justify="center" align="center" direction="column" className={styles.contentContainer}>
              <Heading size="2xl" style={{ marginBottom: '3rem' }}>{gallery.title}</Heading>
              <Wrap className={styles.imagesContainer}>
                {
                gallery.images.map((image, index) => {
                  const gatsbyImage = getImage(image.localFile);
                  return (
                    <WrapItem
                      key={index}
                      onClick={() => {
                        setIsOpen(true);
                        setImages(gallery.images);
                        setCurrentIndex(index);
                      }}
                    >
                      <GatsbyImage className={styles.galleryImage} image={gatsbyImage} />
                    </WrapItem>
                  );
                })
              }
              </Wrap>
            </Flex>
          </Box>
        ))
      }
    </Flex>
  );
}

export default GalleryPage;
